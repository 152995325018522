import React from "react"
import MainFooter from "../../../footer/main_footer"
import MainHeader from "../../../main_header/main_header"
import proj_manage from "../banner/static/proj_manag.webp"
import * as classes from "./project_management_training.module.scss"

import loadable from "@loadable/component"
const Banner = loadable(() => import("../banner/banner"), {
  fallback: <div></div>,
})

export default function ProjectManagement() {
  return (
    <React.Fragment>
      <MainHeader />

      <Banner
        title={"Project Management Training"}
        backgroundImage={proj_manage}
      />
      <div className={classes.text_div}>
        <h2>Project Management Training</h2>
        <p>
          A project management methodology is essentially a set of guiding
          principles and processes for managing a project. Your choice of
          methodology defines how you work and communicate with your team.
          Different project management approaches have their own pros and cons
          for different project types. We allow you to learn how to manage
          projects following standards and your company needs. What’s even
          better is we also offer APMQ certificate training.A modern project
          manager has to get technical to ensure efficiency, so we will be
          teaching you how to manage your projects with the help of MS excel, an
          essential tool. With the help of these skills you will be a natural
          problem solver and be able to guide the project and your team in the
          direction you deem.
        </p>
        <p>Project management training is for:</p>
        <ul>
          <li>Managers</li>
          <li>Project Leads</li>
          <li>Graduates</li>
        </ul>
      </div>
      <MainFooter />
    </React.Fragment>
  )
}
